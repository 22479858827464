import { gql } from '@apollo/client'

export const createOneCredit = gql`
    mutation CreateOneCredit($date: String!, $userId: String!, $amount: Float!) {
        createOneCredit(date: $date, userId: $userId, amount: $amount) {
            amount
            id
        }
    }
`

export const updateUser = gql`
    mutation UpdateUser($userId: String!, $email: String, $role: Role, $isPremium: Boolean) {
        updateUser(userId: $userId, email: $email, role: $role, isPremium: $isPremium) {
            id
        }
    }
`

export const Login = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            user {
                id
            }
            token
        }
    }
`
export const createExplore = gql`
mutation CreateExplore($exRegion: [String!], $region: [String!], $webmUrl: String, $gif: String, $url: String, $thumbnailUrl: String, $aspectRatio: AspectRatioEnum, $style: ArtStyle, $motionScore: MotionScoreEnum, $categoryId: String, $lowResolutionUrl: String, $prompt: String) {
  createExplore(exRegion: $exRegion, region: $region, webmUrl: $webmUrl, gif: $gif, url: $url, thumbnailUrl: $thumbnailUrl, aspectRatio: $aspectRatio, style: $style, motionScore: $motionScore, categoryId: $categoryId, lowResolutionUrl: $lowResolutionUrl, prompt: $prompt) {
    id
    url
    lowResolutionUrl
    prompt
    thumbnailUrl
    motionScore
    style
    createdAt
    updatedAt
    aspectRatio
    location
    region
    exRegion
    gif
    webmUrl
    categoryId
  }
}
`
export const updateExplore = gql`
mutation UpdateExplore($exRegion: [String!], $region: [String!], $webmUrl: String, $gif: String, $url: String, $thumbnailUrl: String, $aspectRatio: AspectRatioEnum, $style: ArtStyle, $motionScore: MotionScoreEnum, $lowResolutionUrl: String, $prompt: String, $categoryId: String, $exploreId: String) {
  updateExplore(exRegion: $exRegion, region: $region, webmUrl: $webmUrl, gif: $gif, url: $url, thumbnailUrl: $thumbnailUrl, aspectRatio: $aspectRatio, style: $style, motionScore: $motionScore, lowResolutionUrl: $lowResolutionUrl, prompt: $prompt, categoryId: $categoryId, exploreId: $exploreId) {
    id
    url
    lowResolutionUrl
    prompt
    thumbnailUrl
    motionScore
    style
    createdAt
    updatedAt
    aspectRatio
    location
    region
    exRegion
    gif
    webmUrl
    categoryId
    category {
      id
      name
    }
  }
}
`
export const deleteExplore = gql`
    mutation DeleteExplore($exploreId: String!) {
        deleteExplore(exploreId: $exploreId)
    }
`
export const createCategory = gql`
    mutation CreateCategory($model: ModelEnum, $prompt: [String!], $gif: String, $description: String, $thumbnailUrl: String, $video: String, $categoryType: CategoryType, $isExplore: Boolean, $name: String) {
        createCategory(model: $model, prompt: $prompt, gif: $gif, description: $description, thumbnailUrl: $thumbnailUrl, video: $video, categoryType: $categoryType, isExplore: $isExplore, name: $name) {
            createdAt
            description
            gif
            id
            isExplore
            model
            name
            prompt
            thumbnail
            type
            updatedAt
            video
        }
    }
`

export const updateCategory = gql`
    mutation UpdateCategory($categoryId: String, $isExplore: Boolean, $categoryType: CategoryType, $name: String, $video: String, $thumbnailUrl: String, $description: String, $gif: String, $prompt: [String!], $model: ModelEnum) {
        updateCategory(categoryId: $categoryId, isExplore: $isExplore, categoryType: $categoryType, name: $name, video: $video, thumbnailUrl: $thumbnailUrl, description: $description, gif: $gif, prompt: $prompt, model: $model) {
            id
            name
            video
            gif
            thumbnail
            description
            prompt
            isExplore
            model
            type
            createdAt
            updatedAt
        }
    }
`

export const deleteCategory = gql`
    mutation DeleteCategory($categoryId: String!) {
        deleteCategory(categoryId: $categoryId)
    }
`
export const deleteVideo = gql`
    mutation DeleteVideoAdmin($videoId: String!) {
        deleteVideoAdmin(videoId: $videoId)
    }
`

export const updateSystem = gql`
    mutation UpdateSystem($systemType: SystemTypeEnum!, $provider: SystemProviderEnum!, $system: RunwaySystemEnum!, $updateSystemId: String!) {
        updateSystem(systemType: $systemType, provider: $provider, system: $system, id: $updateSystemId) {
            id
            system
            provider
            type
            createdAt
            updatedAt
        }
    }
`
export const updateFeedStatus = gql`
    mutation UpdateFeedStatus($status: FeedStatus!, $feedId: String!) {
        updateFeedStatus(status: $status, feedId: $feedId) {
            id
        }
    }
`
export const sendFcm = gql`
    mutation SendFcm($message: String!, $title: String!, $fcmId: String!) {
        sendFcm(message: $message, title: $title, fcmId: $fcmId)
    }
`

export const userTransfer = gql`
    mutation UserTransfer($destinationId: String!, $sourceId: String!) {
        userTransfer(destinationId: $destinationId, sourceId: $sourceId)
    }

`

export const updateCredit = gql`
    mutation UpdateCredit($creditId: String!, $expiryDate: DateTime, $amount: Float) {
        updateCredit(creditId: $creditId, expiryDate: $expiryDate, amount: $amount) {
            id
        }
    }

`
export const updateVideoStatus = gql`
    mutation UpdateVideoStatus($status: StatusEnum!, $videoId: String!) {
        updateVideoStatus(status: $status, videoId: $videoId) {
            id
        }
    }
`

export const createRunwayAccount = gql`
    mutation CreateRunwayKey($accountName: String!, $teamId: Float!, $token: String!) {
        createRunwayAccount(accountName: $accountName, teamId: $teamId, token: $token) {
            id
        }
    }
`

export const updateRunwayAccount = gql`
    mutation UpdateRunwayAccount($keyId: String!, $token: String!) {
        updateRunwayAccount(keyId: $keyId, token: $token) {
            id
            accountName
            accountNo
            token
            teamId
            currentUsage
            isUnlimited
            createdAt
            updatedAt
            health {
                isActive
                error
            }
        }
    }
`
export const deleteRunwayAccount = gql`
    mutation DeleteRunwayAccount($accountId: String!) {
        deleteRunwayAccount(accountId: $accountId)
    }
`

export const getSignedUrl = gql`
    mutation GetSignedUrl($fileName: String!) {
        getSignedUrl(fileName: $fileName) {
            id
            url
            contentType
        }
    }
`
export const getObjectUrl = gql`
    mutation GetObjectUrl($eTag: String!, $key: String!) {
        getObjectUrl(eTag: $eTag, key: $key)
    }
`

export const updateAccount = gql`
mutation UpdateAccount($token: String!, $accountId: String!, $type: String!) {
    updateAccount(token: $token, accountId: $accountId, type: $type) {
      success
      message
      runwayAccount {
        id
        accountName
        accountNo
        token
        teamId
        currentUsage
        isUnlimited
        createdAt
        updatedAt
        health {
          isActive
          error
        }
      }
      soraAccount {
        id
        accountName
        token
        createdAt
        updatedAt
      }
      hailuoaiAccount {
        id
        accountName
        accountNo
        token
        teamId
        currentUsage
        createdAt
        updatedAt
      }
    }
  }
`;

export const deleteUserData = gql`
    mutation DeleteUserData($userId: String!) {
    deleteUserData(userId: $userId) {
      success
      message
      deletedItems
    }
  }
`

export const createHailuoaiAccount = gql`
    mutation CreateHailuoaiAccount($accountName: String!, $token: String!) {
    createHailuoaiAccount(accountName: $accountName, token: $token) {
      id
      accountName
      accountNo
      token
      teamId
      currentUsage
      createdAt
      updatedAt
    }
  }
`
export const deleteHailuoaiAccount = gql`
mutation DeleteHailuoaiAccount($accountId: String!) {
    deleteHailuoaiAccount(accountId: $accountId)
  }
`



  export const createSoraAccount = gql`
mutation CreateSoraAccount($accountName: String!, $token: String!) {
    createSoraAccount(accountName: $accountName, token: $token) {
      id
      accountName
      token
      createdAt
      updatedAt
    }
  }
`
export const deleteSoraAccount = gql`
mutation DeleteSoraAccount($accountId: String!) {
    deleteSoraAccount(accountId: $accountId)
  }
`
export const updateMultipleFeedStatus = gql`
    mutation UpdateMultipleFeedStatus($status: FeedStatus!, $feedIds: [String!]!) {
        updateMultipleFeedStatus(status: $status, feedIds: $feedIds) {
            id
            status
        }
    }
`