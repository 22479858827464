import React, { useState, useRef, useEffect } from 'react';
import { Image, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './VideoPreview.css';

const VideoPreview = ({ thumbnailUrl, videoUrl }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const hoverTimeoutRef = useRef(null);

  // Clear timeout on component unmount
  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    // Add a small delay before showing the modal to prevent accidental triggers
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovering(true);
    }, 300); // 300ms delay
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setIsHovering(false);
    setIsVideoLoaded(false);
  };

  const handleVideoError = (error) => {
    console.error('Video loading error:', error);
  };

  return (
    <div 
      className="video-preview-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Image
        src={thumbnailUrl}
        alt="Thumbnail"
        preview={false}
        style={{
          width: '120px',
          height: '80px',
          objectFit: 'cover',
          borderRadius: '4px',
        }}
      />
      
      <Modal
        open={isHovering}
        footer={null}
        closable={true}
        closeIcon={
          <CloseOutlined 
            className="modal-close-icon"
          />
        }
        maskClosable={true}
        onCancel={handleMouseLeave}
        width={800}
        centered
        className="video-preview-modal"
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
        bodyStyle={{ padding: 0 }}
      >
        <div className="video-modal-container">
          <video
            className="modal-video-player"
            autoPlay
            muted
            loop
            controls
            onLoadedData={() => setIsVideoLoaded(true)}
            onError={handleVideoError}
            playsInline
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>
    </div>
  );
};

export default VideoPreview; 