import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { 
  Table, 
  Card, 
  Input, 
  Select, 
  Space, 
  Image,
  Row,
  Col,
  Tag,
  Button,
  message,
} from 'antd';
import { SearchOutlined, CheckOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getAdminFeed } from '../../graphql/queries';
import { updateMultipleFeedStatus } from '../../graphql/mutation';
import VideoPreview from '../../components/VideoPreview/VideoPreview';
import './Feed.css';

const { Option } = Select;

const feedStatusArray = [
  { value: 'PENDING', name: 'Pending', color: 'gold' },
  { value: 'CONFIRMED', name: 'Confirmed', color: 'green' },
  { value: 'REJECTED', name: 'Rejected', color: 'red' }
];

const aspectRatioArray = [
  { value: 'WIDESCREEN', name: 'Widescreen' },
  { value: 'PORTRAIT', name: 'Portrait' },
  { value: 'SQUARE', name: 'Square' },
  { value: 'FULL_SCREEN', name: 'Full Screen' },
  { value: 'TABLET', name: 'Tablet' },
  { value: 'ULTRAWIDE', name: 'Ultrawide' }
];

const getStatusTag = (status) => {
  const statusConfig = feedStatusArray.find(item => item.value === status) || {
    value: status,
    name: status,
    color: 'default'
  };
  
  return (
    <Tag color={statusConfig.color} style={{ fontSize: '14px', padding: '4px 8px' }}>
      {statusConfig.name}
    </Tag>
  );
};

const Feed = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Initialize state from URL params
  const [searchInput, setSearchInput] = useState(searchParams.get('search') || '');
  const [status, setStatus] = useState(searchParams.get('status') || 'ALL');
  const [aspectRatio, setAspectRatio] = useState(searchParams.get('aspectRatio') || 'ALL');
  const [pagination, setPagination] = useState({
    current: parseInt(searchParams.get('page')) || 1,
    pageSize: parseInt(searchParams.get('pageSize')) || 5,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [updateFeeds] = useMutation(updateMultipleFeedStatus);

  const { loading, data, refetch } = useQuery(getAdminFeed, {
    variables: {
      status: status === 'ALL' ? null : status,
      rcUserId: searchInput || null,
      page: pagination.current - 1, // GraphQL typically uses 0-based pagination
      pageSize: pagination.pageSize,
      aspectRatio: aspectRatio === 'ALL' ? null : aspectRatio,
    },
    fetchPolicy: 'network-only',
  });

  // Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams();
    if (searchInput) params.set('search', searchInput);
    if (status) params.set('status', status);
    if (aspectRatio) params.set('aspectRatio', aspectRatio);
    params.set('page', pagination.current.toString());
    params.set('pageSize', pagination.pageSize.toString());
    setSearchParams(params);
  }, [searchInput, status, aspectRatio, pagination, setSearchParams]);

  // Add "All" option to the arrays
  const statusOptions = [
    { value: 'ALL', name: 'All Status', color: 'default' },
    ...feedStatusArray
  ];

  const aspectRatioOptions = [
    { value: 'ALL', name: 'All Ratios' },
    ...aspectRatioArray
  ];

  const columns = [
    {
      title: 'Feed ID',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
    },
    {
      title: 'Like Count',
      dataIndex: 'likeCount',
      key: 'likeCount',
      width: '10%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status) => getStatusTag(status),
    },
    {
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
      width: '20%',
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnailUrl',
      key: 'thumbnailUrl',
      width: '25%',
      render: (thumbnailUrl, record) => (
        <VideoPreview 
          thumbnailUrl={thumbnailUrl}
          videoUrl={record.videoUrl}
        />
      ),
    },
  ];

  const handleSearch = (value) => {
    setSearchInput(value);
    setPagination(prev => ({ ...prev, current: 1 })); // Reset to first page
    refetch({
      status: status === 'ALL' ? null : status,
      rcUserId: value || null,
      page: 0,
      pageSize: pagination.pageSize,
      aspectRatio: aspectRatio === 'ALL' ? null : aspectRatio,
    });
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    setPagination(prev => ({ ...prev, current: 1 }));
    refetch({
      status: value === 'ALL' ? null : value,
      rcUserId: searchInput || null,
      page: 0,
      pageSize: pagination.pageSize,
      aspectRatio: aspectRatio === 'ALL' ? null : aspectRatio,
    });
  };

  const handleAspectRatioChange = (value) => {
    setAspectRatio(value);
    setPagination(prev => ({ ...prev, current: 1 }));
    refetch({
      status: status === 'ALL' ? null : status,
      rcUserId: searchInput || null,
      page: 0,
      pageSize: pagination.pageSize,
      aspectRatio: value === 'ALL' ? null : value,
    });
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
    refetch({
      status: status === 'ALL' ? null : status,
      rcUserId: searchInput || null,
      page: newPagination.current - 1,
      pageSize: newPagination.pageSize,
      aspectRatio: aspectRatio === 'ALL' ? null : aspectRatio,
    });
  };

  const handleRowClick = (record, event) => {
    // Check if click is on or near checkbox area
    if (
      event.target.closest('.ant-checkbox-wrapper') || // Checkbox itself
      event.target.closest('.ant-table-selection-column') || // Checkbox column
      event.target.closest('.video-preview-container') || 
      event.target.closest('.ant-modal')
    ) {
      return; // Do nothing if clicked on checkbox area or video preview
    }
    navigate(`/video/${record.videoId}`);
  };

  const dataSource = data?.getAdminFeed?.feed?.map(item => {
    console.log('Video data:', item.video); // Debug log
    return {
      key: item.id,
      id: item.id,
      videoId: item.video.id,
      likeCount: item.likeCount,
      status: item.status,
      thumbnailUrl: item.video.thumbnailUrl,
      videoUrl: item.video.url,
      userName: item.user.userName || 'N/A',
    };
  }) || [];

  // Add row selection config
  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  // Add batch update handler
  const handleBatchUpdate = async (newStatus) => {
    try {
      await updateFeeds({
        variables: {
          feedIds: selectedRows,
          status: newStatus,
        },
      });
      
      message.success(`Successfully updated ${selectedRows.length} feeds to ${newStatus}`);
      setSelectedRows([]); // Clear selection
      refetch(); // Refresh the table
    } catch (error) {
      message.error('Failed to update feeds: ' + error.message);
    }
  };

  return (
    <div className="feed-container">
      <div className="filter-section">
        <Row className="filter-row">
          <div className="filter-group">
            <span className="filter-label">Status</span>
            <Select
              value={status}
              onChange={handleStatusChange}
              defaultValue="ALL"
            >
              {statusOptions.map(item => (
                <Option key={item.value} value={item.value}>
                  {item.value === 'ALL' ? item.name : (
                    <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Tag color={item.color} style={{ margin: 0 }}>•</Tag>
                      {item.name}
                    </span>
                  )}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter-group">
            <span className="filter-label">Aspect Ratio</span>
            <Select
              value={aspectRatio}
              onChange={handleAspectRatioChange}
              defaultValue="ALL"
            >
              {aspectRatioOptions.map(item => (
                <Option key={item.value} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </Row>
        
        {selectedRows.length > 0 && (
          <div className="batch-actions-row">
            <div className="batch-actions-left">
              <span className="selected-count">
                <CheckOutlined style={{ marginRight: 8 }} />
                {selectedRows.length} {selectedRows.length === 1 ? 'item' : 'items'} selected
              </span>
            </div>
            <div className="batch-actions-right">
              <Button
                type="primary"
                onClick={() => handleBatchUpdate('CONFIRMED')}
                icon={<CheckCircleOutlined />}
              >
                Confirm Selected
              </Button>
              <Button
                danger
                onClick={() => handleBatchUpdate('REJECTED')}
                icon={<CloseCircleOutlined />}
              >
                Reject Selected
              </Button>
            </div>
          </div>
        )}
      </div>

      <Card>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          pagination={{
            ...pagination,
            total: data?.getAdminFeed?.count || 0,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: (event) => handleRowClick(record, event),
            style: { cursor: 'pointer' }
          })}
        />
      </Card>
    </div>
  );
};

export default Feed; 